<template>
  <v-container>
    <v-toolbar
      color="blue-grey darken-3"
      dark
      dense
    >
      <span>Product Category</span>
      <v-spacer></v-spacer>
      <v-icon>mdi-archive-cog</v-icon>
    </v-toolbar>
    <v-data-table
      :items="prdCatList"
      :headers="headers"
      :loading="this.prdCatGetting"
    ></v-data-table>
  </v-container>
</template>
<script>
import prdCatRepository from '@/repositories/product-category.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode'

const getPrdCatDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
  ],
})

export default {
  name: 'ProductCategoryTable',
  created () {
    this.getPrdCat()
    this.websocketEvent()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('prd-cat'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
      token: state => state.auth.token,
    }),
  },
  methods: {
    getPrdCat () {
      if (this.prdCatGetting) return
      this.prdCatGetting = true
      this.prdCatGetErrors = []
      getPrdCatDelay(() => {
        prdCatRepository.index()
          .then(({ data }) => {
            this.prdCatList = data.map((e, i) => {
              e.index = i + 1
              return e
            })
          })
          .catch(e => { this.prdCatGetErrors = errorHandler(e) })
          .then(() => { this.prdCatGetting = false })
      })
    },
    websocketEvent () {
      const userId = jwtDecode(this.token).sub
      this.echo.channel('database.updated')
        .listen('ProductCategoryUpdatedEvent', c => { this.getPrdCat() })
    },
  },
}
</script>
